<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <label>Grupo</label>
        <b-form-select
          :options="groups"
          v-model="model.selectedGroup"
        ></b-form-select>
      </b-col>
    </b-row>
    <hr />
    <span class="text-muted">Selecione as unidades</span>
    <hr />
    <b-row
      class="mt-1 ml-3"
      v-for="(unities, idx) in chunkedUnities"
      :key="idx"
    >
      <b-col v-for="unity in unities" :key="unity.id">
        <div class="custom-control custom-checkbox select-box">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="unity.id"
            :checked="model.selectedUnities.includes(unity.id)"
            :value="unity.id"
            v-model="model.selectedUnities"
          />
          <label class="custom-control-label" :for="unity.id">{{
            unity.company_name
          }}</label>
        </div>
      </b-col>
    </b-row>  
  </div>
</template>

<script>
export default {
  props: ["modelUnities", "modelSelectedGroup"],
  data() {
    return {
      model: {
        selectedUnities: [],
        selectedGroup: "",
      },
    };
  },
  watch: {
    modelSelectedGroup: {
      handler(v) {
        this.model.selectedGroup = v;
      },
      immediate: true,
    },
    modelUnities: {
      handler(v) {
        this.model.selectedUnities = v;
      },
      immediate: true,
    },
  },
  computed: {
    unities() {
      return _.map(this.$store.state.permissions.unities, (unity) => {
        return { value: unity.id, text: unity.company_name };
      });
    },
    groups() {
      return _.map(this.$store.state.permissions.groups, (group) => {
        return { value: group.id, text: group.description };
      });
    },
    chunkedUnities() {
      return _.chunk(this.$store.state.permissions.unities, 2);
    },
  },
  methods: {
    get_model() {
      console.log("call");
      return this.model;
    },
    // checkItem(id) {
    //   if (this.model.selectedUnities.includes(id)) {
    //     const index = _.findIndex(
    //       this.model.selectedUnities,
    //       (item) => item == id
    //     );
    //     this.model.selectedUnities.splice(index, 1)
    //   } else {
    //     this.model.selectedUnities.push(id);
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  padding: 0.7rem;
  padding-left: 15px;
  border: 1px solid transparent;
}
.select-box:hover {
  border: 1px solid #007bff;
}
</style>